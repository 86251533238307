import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { fill_horizontal_cross_center, fill_vertical_all_center, fill_vertical_cross_center, flex, } from '~/modules/AppLayout/FlexGridCss';
export const fadeIn = keyframes `
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
export const fadeInTransformY = keyframes `
  0% {
    opacity: 0;
    transform: translateY(50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;
export const fadeInTransformX = keyframes `
  0% {
    opacity: 0;
    transform: translateX(50%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;
export const styleds = {
    Sidebar: styled.div `
    ${fill_vertical_cross_center};
    padding: 4px;
    gap: 8px;
  `,
    SidebarTitle: styled.div `
    ${fill_vertical_all_center};
    width: 100%;
    height: 32px;
    border-radius: 5px;
    box-shadow: 0 0 8px 1px #e1e1e1;
    background-color: #f1f3f9;
    border: 1px solid #dddddd;
  `,
    ContentCard: styled.div `
    ${flex.v.allCenter}
    width: 100%;
    box-shadow: 0 0 8px 1px #eeeeee;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      transform: scale(1.01);
      transition: 0.3s;
    }
    animation: ${fadeIn} 2.5s;
  `,
    ValueItem: styled.div `
    ${fill_horizontal_cross_center};
    height: 32px;
  `,
    RankTypeButton: styled.div `
    ${fill_vertical_all_center};
    width: 50%;
    height: 20px;
    font-size: 13px;
    line-height: 20px;
    background-color: #ededed;
    border-radius: 10px;
    cursor: pointer;
    &:hover {
      background-color: #e1e1e1;
      transition: 0.3s;
    }
    &:active {
      background-color: #c1c1c1;
      color: #ffffff;
      transition: 0.3s;
    }
  `,
};
