import { css } from '@emotion/react';
import { memo } from 'react';
import { fill_vertical_all_center, flex, jc } from '~/modules/AppLayout/FlexGridCss';
export const LineProgressbar = memo(function LineProgressbar(props) {
    const max = props.maxValue;
    const min = props.minValue;
    const open = props.ohlc[0];
    const high = props.ohlc[1];
    const low = props.ohlc[2];
    const close = props.ohlc[3];
    //紅K
    const redBar = close > open;
    //綠K
    const greenBar = close < open;
    const barColor = redBar ? '#eb4936' : greenBar ? '#12aa00' : '#cccccc';
    //長條圖全部總長
    const range = max - min;
    //根據(高-低)對應長條圖寬度
    const width = (Math.abs(high - low) * 100) / range;
    //防止十字線沒有東西可以顯示
    const barWidth = width === 0 ? 1 : width.toFixed(2);
    //寬度起始位置
    const start = ((Math.min(high, low) - min) / (max - min)) * 100;
    const end = ((Math.max(high, low) - min) / (max - min)) * 100;
    const barPosition = redBar ? start : start;
    return (<div css={css `
        ${flex.v.allCenter}
        width: 232px;
        height: 60px;
        font-size: 12px;
        gap: 2px;
        color: #1b1851;
      `}>
      <div css={css `
          ${flex.h.allCenter};
          ${jc.spaceBetween};
          width: 100%;
          height: 16px;
        `}>
        <p>最低{parseFloat(props.minValue.toFixed(2))}</p>
        <p css={css `
            width: auto;
            background-color: #0774ffd1;
            height: 16px;
            font-size: 10px;
            color: #ffffff;
            padding: 0px 4px;
            border-radius: 4px;
          `}>
          {props.children}
        </p>
        <p>最高{parseFloat(props.maxValue.toFixed(2))}</p>
      </div>

      <div css={css `
          width: 100%;
          height: 8px;
          background-color: #dedede;
          border-radius: 4px;
        `}>
        <div css={css `
            ${fill_vertical_all_center}
            width: ${barWidth}%;
            height: 8px;
            background-color: ${barColor};
            border-radius: 4px;
            margin-left: ${barPosition}%;
          `}/>
        {/* <div
          css={css`
            ${flex.h.crossCenter}
            width: 100%;
            height: 8px;
            border-radius: 0px;
            margin-left: ${start - 1}%;
          `}
        >
          <svg
            width={12}
            height={8}
          >
            <path
              fill={'#252525'}
              d={`M10 8H2a1 1 0 0 1-.8-1.6l4-5.33a1 1 0 0 1 1.6 0l4 5.33A1 1 0 0 1 10 8z`}
            />
          </svg>
        </div> */}
      </div>
    </div>);
});
