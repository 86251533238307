import { css } from '@emotion/react';
import { memo, useState, useEffect } from 'react';
import { useSnapshot } from 'valtio';
import { fontWeight600 } from '~/css/font';
import { fill_horizontal_all_center } from '~/modules/AppLayout/FlexGridCss';
import { store } from '~/pages/heineken_template/_private/store';
import { eurexIndicatorsValueStorage } from '~/trades/indicators/example/eurexIndicatorsValueStorage';
export const TrendState = memo(function TrendState() {
    const [show, setShow] = useState(false);
    const state = useSnapshot(eurexIndicatorsValueStorage);
    const mainTrend = state.powerTrend;
    const macdTrend = state.powerMacd;
    const dmiTrend = state.powerDMI;
    const secondaryTrend = macdTrend + dmiTrend;
    const longState = secondaryTrend >= 2;
    const shortState = secondaryTrend <= -2;
    const longSwingState = secondaryTrend < 0 && secondaryTrend > -2;
    const shortSwingState = secondaryTrend < 2 && secondaryTrend > 0;
    const charting = useSnapshot(store.charting);
    const interval = charting.interval;
    const symbol = charting.symbol;
    useEffect(() => {
        setShow(true);
        setTimeout(() => {
            setShow(false);
        }, 1000);
    }, [interval, symbol]);
    const mainState = () => {
        if (mainTrend === 1) {
            return '多方';
        }
        if (mainTrend === -1) {
            return '空方';
        }
    };
    const intervalState = () => {
        if (interval === '1D' || interval === '1W' || interval === '1M') {
            return '長線';
        }
        if (interval === '30' || interval === '60') {
            return '短線';
        }
        if (interval === '15') {
            return '短線';
        }
        if (interval <= '5') {
            return '極短線';
        }
    };
    const stateColor = () => {
        if (mainTrend === 1 && show === false) {
            return '#e45751';
        }
        else if (mainTrend === -1 && show === false) {
            return '#54a843';
        }
        else if (show === true) {
            return '#aaaaaa';
        }
    };
    const secondary = () => {
        if (mainTrend === 1 && longState) {
            return '趨勢';
        }
        if (mainTrend === 1 && !longState && !longSwingState) {
            return '拉回';
        }
        if (mainTrend === 1 && !longState && longSwingState) {
            return '整理';
        }
        if (mainTrend === -1 && shortState) {
            return '趨勢';
        }
        if (mainTrend === -1 && !shortState && !shortSwingState) {
            return '反彈';
        }
        if (mainTrend === -1 && !shortState && shortSwingState) {
            return '整理';
        }
    };
    return (<div css={css `
        ${fill_horizontal_all_center};
        height: 36px;
        width: 100%;
        background-color: ${stateColor()};
        border-radius: 5px;
        color: #fefefe;
        ${fontWeight600};
      `}>
      {show === true ? ('載入中') : (<div>
          {intervalState()}
          {mainState()}
          {secondary()}
        </div>)}
    </div>);
});
