/* eslint-disable react-hooks/exhaustive-deps */
import { debounce, defaults, values } from 'lodash';
import { useEffect } from 'react';
import { proxy } from 'valtio';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { useSignalrConnectionWatcher2 } from '~/modules/SDK/Signalr/useSignalrConnectionWatcher2';
import { useSignalrSetUid2 } from '~/modules/SDK/Signalr/useSignalrSetUid2';
import { useSignalrSymbolsWatcher2 } from '~/modules/SDK/Signalr/useSignalrSymbolsWatcher2';
export const hookStore = proxy({
    /** `useStart(options) 的 options` */
    options: {
        /** 預設 30 秒。檢查一次連線，若斷線則自動重新連線 */
        reconnectCheckInterval: (30 * 1000),
    },
    debouncedConnect: debounce(async () => {
        return await signalrStore2.start();
    }, 1000),
    debouncedInvokeSubscribe: debounce(async () => {
        return await signalrStore2.invokeSubscriptions();
    }, 1000),
});
/** # v2.1 版本 */
export const useSignalrStart2_1 = (options) => {
    useEffect(() => {
        hookStore.options = defaults(options, hookStore.options);
    }, [...values(options)]);
    useSignalrConnectionWatcher2();
    useSignalrSetUid2();
    useSignalrSymbolsWatcher2();
};
