import { memo, useEffect } from 'react';
import { useSnapshot } from 'valtio';
import { eurexIndicatorsValueStorage } from '~/trades/indicators/example/eurexIndicatorsValueStorage';
import { PowerGauges, PriceGauges } from './component/Gauges';
import { styleds } from './styleds';
import { TrendState } from './component/TrendState';
import TrendTable from './component/TrendTable';
import { LineProgressbar } from './component/LineProgressbar';
export const Eurex_Sidepane1 = memo(function Eurex_Sidepane1() {
    const state = useSnapshot(eurexIndicatorsValueStorage);
    const highest5day = state.highestHigh5;
    const lowest5day = state.lowestLow5;
    const highest20day = state.highestHigh20;
    const lowest20day = state.lowestLow20;
    const highest60day = state.highestHigh60;
    const lowest60day = state.lowestLow60;
    const current5day = state.rsv5;
    const current20day = state.rsv20;
    const current60day = state.rsv60;
    const close = state.dayClose;
    const open = state.dayOpen;
    const high = state.dayHigh;
    const low = state.dayLow;
    const ohlc = [open, high, low, close];
    useEffect(() => {
        state.rsv5;
    }, [current5day, state.rsv5]);
    return (<styleds.Sidebar>
      <styleds.SidebarTitle>盤勢狀態</styleds.SidebarTitle>
      <TrendState />
      <styleds.SidebarTitle>位階觀察</styleds.SidebarTitle>
      <styleds.ContentCard>
        <PriceGauges currentValue={(current5day + current20day + current60day) / 3 || 0}/>
        <LineProgressbar ohlc={ohlc} minValue={lowest5day} maxValue={highest5day}>
          5範圍
        </LineProgressbar>
        <LineProgressbar ohlc={ohlc} minValue={lowest20day} maxValue={highest20day}>
          20範圍
        </LineProgressbar>
        <LineProgressbar ohlc={ohlc} minValue={lowest60day} maxValue={highest60day}>
          60範圍
        </LineProgressbar>
      </styleds.ContentCard>

      <styleds.SidebarTitle>動能統計</styleds.SidebarTitle>
      <styleds.ContentCard>
        <PowerGauges currentValue={state.highestVolume5 / state.highestVolume20}/>
      </styleds.ContentCard>
      <styleds.SidebarTitle>趨勢統計</styleds.SidebarTitle>
      <styleds.ContentCard>
        <TrendTable.Dispaly />
      </styleds.ContentCard>
    </styleds.Sidebar>);
});
