import { useIntervalNow } from '~/hooks/useIntervalNow';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { hookStore } from '~/modules/SDK/Signalr/useSignalrStart2_1';
/**
 * 維持前端與後端的連線
 *
 * ## 需求
 *
 * - 每30秒檢查一次斷線重連
 * - 休眠過久後，斷線重連（Mac 可透過 sleep 復現）
 */
export const useSignalrConnectionWatcher2 = () => {
    //
    // 同步 signalr官方lib 的 connection.state 到我們的 state
    useIntervalNow(() => {
        const noChanges = signalrStore2.state.connectionState === signalrStore2.connection.state;
        if (noChanges)
            return;
        signalrStore2.state.connectionState = signalrStore2.connection.state;
    }, 1000);
    //
    // 定期檢查並重新連線
    useIntervalNow(() => {
        if (signalrStore2.state.isConnected)
            return;
        hookStore.debouncedConnect();
    }, hookStore.options.reconnectCheckInterval || null);
};
