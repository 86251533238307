import { css } from '@emotion/react';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { ImArrowDownRight2, ImArrowUpRight2 } from 'react-icons/im';
import { eurexIndicatorsValueStorage } from '~/trades/indicators/example/eurexIndicatorsValueStorage';
import { flex, jc } from '~/modules/AppLayout/FlexGridCss';
import { useThemeStore } from '~/components/theme/useThemeStore';
import styled from '@emotion/styled';
const TrendTable = memo(function TrendTable(props) {
    /** 5 / 10 / 20 單位 */
    const intervalText = props.intervalText ?? '日';
    const theme = useThemeStore(t => t.theme);
    const state = useSnapshot(eurexIndicatorsValueStorage);
    const ma5 = state.ma5;
    const ma20 = state.ma20;
    const ma60 = state.ma60;
    const ma120 = state.ma120;
    const bias5 = state.bias5;
    const bias20 = state.bias20;
    const bias60 = state.bias60;
    const bias120 = state.bias120;
    const slope5 = state.slope5;
    const slope20 = state.slope20;
    const slope60 = state.slope60;
    const slope120 = state.slope120;
    const offset5 = state.offsetValue5;
    const offset20 = state.offsetValue20;
    const offset60 = state.offsetValue60;
    const offset120 = state.offsetValue120;
    const Item = memo(function Item(props_) {
        const stringColor = () => {
            if (props_.bias > 0) {
                return classesFill.upFill;
            }
            if (props_.bias < 0) {
                return classesFill.dnFill;
            }
            if (props_.bias === 0) {
                return classesFill.defaultFill;
            }
        };
        return (<div css={css `
          ${flex.h.allCenter}
          width:100%;
          height: 32px;
          ${jc.spaceAround}
        `}>
        <div>{props_.children}</div>
        <div>{props_.maValue.toFixed(2)}</div>
        <div css={stringColor()} className={theme}>
          {props_.bias.toFixed(2)}
        </div>
        <div>{props_.offset.toFixed(2)}</div>
      </div>);
    });
    const ItemSymbol = memo(function ItemSymbol(item_props) {
        const displayTrend = (value) => {
            if (value > 0) {
                return (<ImArrowUpRight2 css={classesFill.upFill} className={theme}/>);
            }
            if (value < 0) {
                return (<ImArrowDownRight2 css={classesFill.dnFill} className={theme}/>);
            }
            if (value === 0 || isNaN(value)) {
                return '---';
            }
        };
        return (<div css={css `
          ${flex.h.allCenter}
          width:100%;
          height: 32px;
          ${jc.spaceAround}
        `}>
        <div>趨勢方向</div>
        <div>{displayTrend(item_props.value1)}</div>
        <div>{displayTrend(item_props.value2)}</div>
        <div>{displayTrend(item_props.value3)}</div>
        <div>{displayTrend(item_props.value4)}</div>
      </div>);
    });
    return (<classes.Root css={baseStyled}>
      <classes.title className={theme}>
        <div>趨勢評分</div>
        <div>5{intervalText}</div>
        <div>20{intervalText}</div>
        <div>60{intervalText}</div>
        <div>120{intervalText}</div>
      </classes.title>
      <ItemSymbol value1={slope5} value2={slope20} value3={slope60} value4={slope120}/>
      <classes.title className={theme}>
        <div>對象</div>
        <div>數值</div>
        <div>乖離</div>
        <div>扣抵</div>
      </classes.title>
      <Item maValue={ma5} bias={bias5} offset={offset5}>
        移動平均(5)
      </Item>
      <Item maValue={ma20} bias={bias20} offset={offset20}>
        移動平均(20)
      </Item>
      <Item maValue={ma60} bias={bias60} offset={offset60}>
        移動平均(60)
      </Item>
      <Item maValue={ma120} bias={bias120} offset={offset120}>
        移動平均(120)
      </Item>
    </classes.Root>);
});
const classes = {
    Root: styled.div `
    ${flex.v.crossCenter}
    width: 100%;
    font-size: 12px;
    padding: 4px;
  `,
    title: styled.div `
    ${flex.h.allCenter}
    width:100%;
    height: 32px;
    ${jc.spaceAround};
  `,
};
const baseStyled = css `
  ${classes.Root} {
  }
  ${classes.title} {
    &.light {
      background-color: #efefef;
    }
    &.dark {
      background-color: #353535;
    }
  }
`;
const classesFill = {
    defaultFill: css `
    &.light {
      color: #252525;
    }
    &.dark {
      color: #fcfcfc;
    }
  `,
    upFill: css `
    &.light {
      color: #d46057;
    }
    &.dark {
      color: #d46057;
    }
  `,
    dnFill: css `
    &.light {
      color: #69a650;
    }
    &.dark {
      color: #69a650;
    }
  `,
};
export default {
    Dispaly: TrendTable,
    classes,
    classesFill,
};
